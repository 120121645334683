/** This composable is logic for fetching and saving BOMs to hide the complexity of local storage vs. API */
import * as v from "valibot";

const VALID_EXTENSIONS = ["xls", "xlsx", "csv", "tsv"];
const LOCAL_STORAGE_KEY = "ecia-bom-anonymous";

type UploadCompleteResponse = {
    truncated: boolean;
    res?: BOMSearch; // only if it's anonymous :-/
    Id?: string; // only if it's not anonymous
};

// Partial BOM is suitable for sending to API
function getPartialBom(bom: BOMSearch): Partial<Omit<BOMSearch, "Parts">> & { Parts: Partial<BOMSearchPart>[] } {
    const { Name, Distributors, SortOrder, StockFilter, BatchSize, Parts: parts } = bom;
    const partialParts = Array.isArray(parts) ? parts.map(getPartialPart) : [];
    return { Name, Distributors, SortOrder, StockFilter, BatchSize, Parts: partialParts };
}

function getPartialPart(part: BOMSearchPart): Partial<BOMSearchPart> {
    const {
        Id,
        Key,
        PartId,
        PartNumber,
        PartNumberScrubbedNonMeaningful,
        ManufacturerId,
        RootManufacturerId,
        ManufacturerName,
        Quantity,
        SortNum,
        PartClickHash,
        InternalReferenceNumber,
    } = part;
    return {
        Id,
        Key,
        PartId,
        PartNumber,
        PartNumberScrubbedNonMeaningful,
        ManufacturerId,
        RootManufacturerId,
        ManufacturerName,
        Quantity,
        SortNum,
        PartClickHash,
        InternalReferenceNumber,
    };
}

export default function useBOM() {
    const { t } = useI18n();
    const api = useApi();
    const isAuthenticated = useStateIsAuthenticated();
    const BomNameSchema = v.pipe(v.string(), v.trim(), v.nonEmpty(t("BOMTool.BOMNameRequired")));

    async function fetchBOMs(query?: Record<string, string | number | boolean | undefined>) {
        if (isAuthenticated.value) {
            return api<BOMSearch[]>("api/bom", { query });
        } else {
            const localBOM = await fetchBOM("local");
            return localBOM ? [localBOM] : [];
        }
    }

    async function fetchBOM(hash: string | string[]) {
        const hashId = Array.isArray(hash) ? hash[0] : hash;
        if (isAuthenticated.value) {
            return api<BOMSearch>(`api/bom/${encodeURI(hashId)}`, { method: "GET" });
        } else {
            const bomString = localStorage.getItem(LOCAL_STORAGE_KEY);
            if (!bomString) return;
            const localBOM = JSON.parse(bomString) as BOMSearch;
            return { ...localBOM, HashId: hashId };
        }
    }

    function getDefaultBOM() {
        // TODO: we don't have to keep fetching this, it never changes
        return api<BOMSearch>("api/bom/default");
    }

    function saveBOM(bom: BOMSearch) {
        if (isAuthenticated.value) {
            const body = getPartialBom(bom);
            if (bom.HashId) {
                return api<BOMSearch>(`api/bom/${encodeURI(bom.HashId)}`, { method: "PUT", body });
            } else {
                return api<BOMSearch>("api/bom", { method: "POST", body });
            }
        } else {
            BomHelpers.setAnonBOM(bom);
            return { ...bom, HashId: "local" };
        }
    }

    //Creates a new BOM with just a name and optionally the first part
    async function createBOM(name: string, part?: Partial<BOMSearchPart>) {
        const parsedName = v.parse(BomNameSchema, name);

        let newBOM = await getDefaultBOM();
        newBOM.Name = parsedName;
        if (part) newBOM.Parts = [];
        newBOM = await saveBOM(newBOM);
        return part ? addBOMPart(newBOM, part) : newBOM;
    }

    // Given an existing BOM and a part to search for, returns a new copy of the BOM with a complete part from the API
    async function addBOMPart(bom: BOMSearch, part: Partial<BOMSearchPart>): Promise<BOMSearch> {
        if (!isAuthenticated.value && bom.Parts.length >= BOM_TOOL.MAX_PARTS) {
            if (bom.Parts.length >= BOM_TOOL.MAX_PARTS) {
                throw new Error(t("BOMTool.AddTooManyPartsWarning"));
            }
        }

        const completePart = await api<BOMSearchPart>(`api/bom/${encodeURI(bom.HashId)}/part`, {
            method: "POST",
            body: part,
        });

        const newBOM: BOMSearch = { ...bom }; // shallow copy
        if (!Array.isArray(newBOM.Parts)) newBOM.Parts = [];

        if (!isAuthenticated.value) {
            completePart.Id = BomHelpers.getAnonBomNextPartId(newBOM);
        }

        newBOM.Parts.push(completePart);
        if (isAuthenticated.value) return { ...newBOM, PartExists: true }; // API already linked the part, don't save again

        // for anon users, we need to store the complete BOM with part
        const savedBOM = await saveBOM(newBOM);
        return { ...savedBOM, PartExists: true };
    }

    function partExistsInBOM(bom: BOMSearch | null, part: BOMSearchPart) {
        return bom && bom.Parts.some((bomPart) => bomPart.Key === part.Key || bomPart.PartId === part.PartId);
    }

    async function uploadStart(file?: File) {
        if (!file) return;

        const ext = file.name.split(".").pop();
        if (!ext || !VALID_EXTENSIONS.includes(ext.toLowerCase())) {
            throw new Error(t("BOMTool.InvalidFormat"));
        }

        const fileSizeMb = file.size / 1_048_576;
        if (fileSizeMb > MAX_FILE_UPLOAD_MEGABYTES) {
            throw new Error(t("Global.MaxFileUploadSize", [MAX_FILE_UPLOAD_MEGABYTES]));
        }

        const body = new FormData();
        body.append("Files", file);
        return api<BOMImportFileDetail>("/api/bom/upload/start", { method: "POST", body });
    }

    function uploadComplete(bomImportFile: MaybeRefOrGetter<BOMImportFileDetail | null | undefined>) {
        const body = toValue(bomImportFile);
        if (!body) return;

        return api<UploadCompleteResponse>("/api/bom/upload/complete", { method: "POST", body });
    }

    return {
        addBOMPart,
        createBOM,
        fetchBOM,
        fetchBOMs,
        getDefaultBOM,
        partExistsInBOM,
        saveBOM,
        uploadComplete,
        uploadStart,
        BomNameSchema,
    };
}
